import { combineReducers, configureStore } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { campaignReducer, distributeReducer, importReducer, profileReducer, templateReducer, uiReducer } from './slices'
import { tableReducer } from './slices/table'

const isDevEnv = process.env.NODE_ENV !== 'production'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: []
}

const reducers = combineReducers({
  profile: profileReducer,
  table: tableReducer,
  campaign: campaignReducer,
  distribute: distributeReducer,
  ui: uiReducer,
  template: templateReducer,
  import: importReducer
})
const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    const middlewares = [
      ...getDefaultMiddleware({
        thunk: false,
        // serializableCheck: false,
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
        }
      })
    ]
    if (isDevEnv) {
      middlewares.push(logger as never)
    }
    return middlewares
  },
  devTools: isDevEnv
})

export const persistor = persistStore(store)
export type RootState = ReturnType<typeof reducers>
