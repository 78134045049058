import { EnumFormatRank, ImportCustomerEntity } from 'models/customer'

export enum EnumCustomerImportCategory {
  ALL = 'all',
  VALID = 'valid',
  INVALID = 'invalid'
}
export interface ImportStateEntity {
  file?: File
  is_preview?: boolean
  customers?: ImportCustomerEntity[]
  category?: EnumCustomerImportCategory
}

export enum EnumGender {
  Female = 'female',
  Male = 'male',
  Unknown = 'unknown'
}
