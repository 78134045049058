import React from 'react'

import { Stack, Tooltip, Typography } from '@mui/material'
import { UiAvatar } from 'components'
import { env } from 'config'
import { useSelector } from 'hooks'

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

export function Profile() {
  const { user } = useSelector((x) => x.profile)

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <UiAvatar uri={user?.avatar} style={{ width: 36, height: 36 }} />
      <Stack direction="row" alignItems="flex-start" spacing={0.5} sx={{ ml: 3 }}>
        <Stack direction="column" onClick={() => (window.location.href = `${env.web.forlife}/user/profile`)}>
          <Tooltip title="Chỉnh sửa thông tin">
            <Typography
              sx={{
                fontWeight: '500',
                fontSize: '16px',
                color: '#333',
                '&:hover': {
                  cursor: 'pointer'
                }
              }}
            >
              {user?.name}
            </Typography>
          </Tooltip>
          <Typography
            sx={{
              fontWeight: '400',
              fontSize: '14px',
              color: '#333',
              '&:hover': {
                cursor: 'pointer'
              }
            }}
          >
            {user?.username}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  )
}
