/* eslint-disable @typescript-eslint/indent */
import React, { RefObject, createRef, forwardRef, useImperativeHandle, useState } from 'react'
import { Typography } from '@mui/material'
import { DialogMain, UIDialogRef } from './DialogMain'

export interface GlobalAlertData {
  title: string
  onConfirm?: () => void
}
export const globalPopupRef = React.createRef<any>()
export const globalPopup = {
  show: (data: GlobalAlertData) => {
    globalPopupRef?.current?.show(data)
  },
  hide: () => {
    globalPopupRef?.current?.hide()
  }
}

export const GlobalPopup = forwardRef((props, ref) => {
  const [data, setData] = useState<GlobalAlertData | undefined>()
  const dialogRef: RefObject<UIDialogRef> = createRef()
  useImperativeHandle(ref, () => {
    return { show, hide }
  })

  const show = (data: GlobalAlertData) => {
    setData(data)
    dialogRef?.current?.show()
  }
  const hide = () => {
    dialogRef?.current?.hide()
  }

  return (
    <DialogMain
      ref={dialogRef}
      label=""
      onSubmit={
        data?.onConfirm
          ? () => {
              data?.onConfirm?.()
              hide()
            }
          : undefined
      }
      onCancel={() => {
        hide()
      }}
      onClose={hide}
    >
      <Typography>{data?.title}</Typography>
    </DialogMain>
  )
})
