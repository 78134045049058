import { Stack, Typography } from '@mui/material'
import { ContactMethodLabel, CustomerCareEntity } from 'models'
import React, { FC, useCallback } from 'react'
import { formatDatetime } from 'utils'

interface Props {
  data: CustomerCareEntity
}
export const Info: FC<Props> = ({ data }) => {
  const { customer, logs } = data
  const { name, birthday, score_amount, score_expire, store, phone } = customer ?? {}

  const getFields = useCallback(() => {
    if (!logs) {
      return []
    }

    const fields: string[][] = []
    for (var log of logs) {
      var { content, method, contact_success, created_by, created_at } = log
      fields.push([
        `${created_by.name} ${ContactMethodLabel[method]} lúc ${formatDatetime(created_at)}, ghi chú: ${content}`,
        contact_success ? 'Thành công' : 'Không thành công'
      ])
    }
    return fields
  }, [data])

  const fields = getFields()
  return (
    <Stack direction={'column'} sx={{ display: 'flex', px: 1, py: 1 }}>
      {(!logs || logs.length === 0) && (
        <Typography
          style={{
            fontSize: '12px',
            color: '#000'
          }}
        >
          Nhân viên chưa liên hệ cho khách lần nào
        </Typography>
      )}
      {fields.map(([label, value]) => (
        <Stack direction={'row'} sx={{ alignItems: 'center', py: 0.5, justifyContent: 'space-between', borderBottomWidth: '1px', borderBottomColor: '#333' }}>
          <Typography
            style={{
              fontSize: '12px',
              color: '#000',
              fontWeight: '500',
              flex: 1
            }}
          >
            {label}
          </Typography>
          <Typography
            style={{
              fontSize: '12px',
              color: '#000',
              marginLeft: '10px'
            }}
          >
            {value}
          </Typography>
        </Stack>
      ))}
    </Stack>
  )
}
