/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/indent */
import { Button as MuiButton, Tooltip, Typography } from '@mui/material'
import React from 'react'

interface Props {
  label: string
  type: 'primary' | 'warning' | 'success' | 'default' | 'danger' | 'info'
  icon?: React.JSX.Element
  tooltip?: string
  onClick: (e?: any) => void
}
export function Button({ label, type, tooltip, icon, onClick }: Props) {
  const viewStyle =
    type === 'default'
      ? {
          color: '#333333',
          background: '#FFFFFF',
          borderColor: '#EEEEEE',
          '&:hover': {
            borderColor: '#FFFFFF',
            background: '#EEEEEE'
          }
        }
      : type === 'primary'
        ? {
            backgroundColor: '#2560E5',
            border: '1px solid #2560E5',
            color: '#FFFFFF',
            '&:hover': {
              backgroundColor: '#2560E5',
              border: '1px solid #2560E5',
              color: '#FFFFFF'
            }
          }
        : {}
  return (
    <MuiButton
      variant="outlined"
      onClick={onClick}
      sx={{
        minWidth: '100px',
        ...viewStyle
      }}
    >
      {icon}
      {tooltip ? (
        <Tooltip title={tooltip}>
          <Typography sx={{ ml: '8px' }}>{label}</Typography>
        </Tooltip>
      ) : (
        <Typography sx={{ ml: '8px', fontSize: '13px' }}>{label}</Typography>
      )}
    </MuiButton>
  )
}
