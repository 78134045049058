/* eslint-disable @typescript-eslint/naming-convention */
import SearchIcon from '@mui/icons-material/Search'
import { Box, Card, Grid, Stack, Toolbar } from '@mui/material'
import React, { useCallback, useState } from 'react'

import { Button, IconButton, MyTextField } from 'components'
import _ from 'lodash'
import { EnumTable } from 'models'
import { useDispatch } from 'react-redux'
import { tableActions } from 'store/slices/table'
import RefreshIcon from '@mui/icons-material/Refresh'
import { Label } from '@mui/icons-material'
import { useTableConfig, useTableData } from '../hook'
import { FilterDialog } from './dialog'
import { TagList } from './tag_list'

interface Props {
  code: EnumTable
}
export function Filter({ code }: Props) {
  const { config } = useTableConfig({ code })
  const [open, setOpen] = useState(false)
  const { handleRemoteData } = useTableData(code)
  const [enableRefresh, setEnableRefresh] = useState<boolean>(true)
  const dispatch = useDispatch()
  const handleClickFilter = () => {
    setOpen(true)
  }

  const handleChangeSearch = _.debounce((search) => {
    dispatch(tableActions.changeFilter({ name: code, data: { search } }))
  }, 500)
  const { search } = config ?? {}

  const refreshData = useCallback(() => {
    if (!enableRefresh) {
      return
    }
    setEnableRefresh(false)
    handleRemoteData()
    setTimeout(() => {
      setEnableRefresh(true)
    }, 5000)
  }, [enableRefresh, handleRemoteData])

  if (!config?.search && !config?.filters) {
    return <div />
  }

  return (
    <Card
      sx={{
        padding: '10px',
        maxWidth: '1000px',
        mt: '6px',
        mb: '8px',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignSelf: 'center'
      }}
    >
      <Stack direction="row" alignItems="center" sx={{ width: '100%' }}>
        <Stack direction="row" alignItems="center" sx={{ flex: 1 }}>
          {search && (
            <Box sx={{ width: '30%', mr: '20px' }}>
              <MyTextField
                // width="100%"
                textcolor="#A3A3A3"
                placeholder="Tìm kiếm"
                backgroundColor="#F5F5F5"
                handleChange={handleChangeSearch}
                iconstart={<SearchIcon sx={{ marginRight: '4px' }} color="disabled" />}
              />
            </Box>
          )}
          {config?.filters?.inputs ? <Button label="Bộ lọc" type="default" onClick={handleClickFilter} /> : <></>}
        </Stack>
        {enableRefresh && <IconButton icon={<RefreshIcon sx={{ color: '#027FFF' }} />} label="Làm mới dữ liệu" onClick={refreshData} />}
      </Stack>
      <TagList code={code} />
      <FilterDialog code={code} open={open} onClose={() => setOpen(false)} />
    </Card>
  )
}
