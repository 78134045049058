import { CustomerCareEntity, EnumCustomerGender, SyntaxConfigs, TemplateParam, TemplateParams } from 'models'
import { formatDate } from './formater'
import moment from 'moment'

export class TemplateUtil {
  static keyToString(key: TemplateParam) {
    const { name } = SyntaxConfigs.find((item) => item.key === key) ?? {}
    return `[${name}]`
  }

  static convertToKeyedTemplate(template: string) {
    var result = template
    SyntaxConfigs.forEach(({ name, key }) => {
      result = result.replaceAll(`[${name}]`, `[${key}]`)
    })
    return result
  }

  static convertToNamedTemplate(template: string) {
    var result = template
    SyntaxConfigs.forEach(({ name, key }) => {
      result = result.replaceAll(`[${key}]`, `[${name}]`)
    })
    return result
  }

  static isUseSyntax(template: string, key: TemplateParam) {
    return template.includes(this.keyToString(key))
  }

  static insertSyntax(template: string, key: TemplateParam, pos: number) {
    const syntax_string = this.keyToString(key)
    if (pos < 0 || pos > template.length) {
      return template
    }
    return template.slice(0, pos) + '' + syntax_string + '' + template.slice(pos)
  }

  static removeSyntax(template: string, key: TemplateParam) {
    const syntax_string = this.keyToString(key)
    return template.replace(new RegExp(syntax_string, 'g'), '')
  }

  static getShortPersonName(name: string | undefined) {
    if (!name) {
      return ''
    }
    const words = name.split(' ').filter((str) => str.length > 0)
    return words[words.length - 1]
  }

  static getStoreName = (store_name: string | undefined) => {
    if (!store_name || store_name.length === 0) {
      return ''
    }
    if (store_name.indexOf('T ') === 0) {
      store_name = 'TokyoLife ' + store_name.substring(2)
    }
    if (store_name.indexOf('F ') === 0) {
      store_name = 'Format ' + store_name.substring(2)
    }
    return store_name
  }
  static getTemplateParams(data: CustomerCareEntity): TemplateParams {
    const { customer, carer, unit_store } = data
    const { name, birthday, phone, score_amount, score_expire } = customer ?? {}

    const store = unit_store?.mapping_store
    const expireCountdown = (score_expire: string | undefined) => {
      if (!score_expire) {
        return 'Đã hết hạn'
      }
      const days = moment(score_expire).diff(moment(), 'day')
      if (days <= 0) {
        return 'Đã hết hạn'
      }
      return `Còn ${days} ngày`
    }

    const getGoogleMapUrl = () => {
      var store_name = store?.name ?? ''
      if (store_name.length === 0) {
        return ''
      }
      if (store_name.indexOf('T ') === 0) {
        store_name = 'TokyoLife ' + store_name.substring(2)
      }
      if (store_name.indexOf('F ') === 0) {
        store_name = 'Format ' + store_name.substring(2)
      }
      return `https://www.google.com/maps/search/${encodeURIComponent(store_name)}`
    }
    const store_name = this.getStoreName(store?.name)
    return {
      customer_full_name: name ?? '',
      customer_name: this.getShortPersonName(name),
      customer_birthday: birthday ? formatDate(birthday) : '',
      customer_phone: phone ?? '',
      carer_name: this.getShortPersonName(carer?.name ?? ''),
      carer_full_name: carer?.name ?? '',
      score_amount: (score_amount ?? 0) + '',
      score_amount_value: ((score_amount ?? 0) * 1000).toLocaleString() + ' đồng',
      score_expire: score_expire ? formatDate(score_expire) : '',
      score_expire_countdown: expireCountdown(score_expire),
      store_name: store_name,
      store_address: store?.address ?? '',
      store_map_url: `https://www.google.com/maps/search/${encodeURIComponent(store_name)}`
    }
  }

  static getContent(template: string, data: CustomerCareEntity, gender?: EnumCustomerGender): string {
    const params = this.getTemplateParams(data)

    var content = template
    SyntaxConfigs.forEach(({ key }) => {
      const sub = this.keyToString(key)

      const getValue = () => {
        if (key === 'customer_gender') {
          if (!gender || gender === EnumCustomerGender.Unknown) {
            return ''
          }
          return gender === EnumCustomerGender.Male ? 'anh' : 'chị'
        }
        return params[key]
      }
      const value = getValue()
      content = content.replaceAll(sub, value ?? '')
    })
    return content
  }
}
