import { instanceAxios } from 'utils'
import { ApiRequest } from './interface'

const create = (data: ApiRequest.template.create) => instanceAxios.post(`/templates`, data)

const update = (id: string, data: ApiRequest.template.update) => instanceAxios.patch(`/templates/${id}`, data)

const del = async (id: string, campaign_id: string): Promise<any> => instanceAxios.delete(`/templates/${id}`)

const generateMessages = async (campaign_id: string): Promise<any> => instanceAxios.get(`/templates/generate-messages/${campaign_id}`)
export default {
  create,
  update,
  del,
  generateMessages
}
