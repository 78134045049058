import { CampaignEntity } from './campaign'
import { CareLogEntity } from './care_log'
import { CustomerEntity, ImportCustomerEntity } from './customer'
import { EntityBase } from './entity'
import { OrgUnitEntity } from './org_unit'
import { DistributeUnit } from './redux'
import { StoreEntity } from './store'
import { UserEntity } from './user_portal'

export enum EnumCareStatus {
  FIND_UNIT_CARER = 'find_unit_carer',
  FIND_CARER = 'find_carer',
  NOT_CONTACT_YET = 'not_contact_yet',
  UNABLE_CONTACT = 'unable_contact',
  CONTACT_DONE = 'contact_done'
}

export interface DistributeConfig {
  units: DistributeUnit[]
}
export interface StatCustomerCare {
  id?: string
  distribute_config?: DistributeConfig
  counter: Partial<Record<EnumCareStatus, number>>
  belong_count?: number

  units?: StatCustomerCare[]
}

export interface CustomerCareEntity extends EntityBase {
  customer?: CustomerEntity
  import_data?: ImportCustomerEntity
  unit_store?: OrgUnitEntity
  unit_carer?: OrgUnitEntity
  unit_area?: OrgUnitEntity
  carer?: UserEntity
  campaign?: CampaignEntity
  logs?: CareLogEntity[]
  status: EnumCareStatus
  slug: string
}
export const CareStatusLabel: Record<EnumCareStatus, string> = {
  [EnumCareStatus.FIND_UNIT_CARER]: 'Tìm đơn vị liên hệ',
  [EnumCareStatus.FIND_CARER]: 'Tìm nhân viên liên hệ',
  [EnumCareStatus.NOT_CONTACT_YET]: 'Chưa gọi',
  [EnumCareStatus.UNABLE_CONTACT]: 'Không liên hệ được',
  [EnumCareStatus.CONTACT_DONE]: 'Đã liên hệ'
}
