/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import axios from 'axios'
import { env } from 'config'
import { jwtDecode } from 'jwt-decode'
import { CookiesCustom } from './cookies'

declare module 'axios' {
  export interface AxiosRequestConfig {
    throwAccessDenied?: boolean // is true if you want to self handle access denied exception
  }
}

export const axiosPrivate = axios.create({
  baseURL: env.api.customer_care,
  headers: {
    'Accept-Language': 'en-US',
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
})

let isRefreshToken = false

const refreshToken = CookiesCustom.getRefreshToken()

const _axiosPrivate = axios.create({
  baseURL: env.api.portal,
  headers: {
    'Accept-Language': 'en-US',
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${refreshToken}`
  }
})

const instance = axiosPrivate
const _instance = _axiosPrivate

function createAxiosClient() {
  instance.interceptors.request.use(
    (config) => {
      const access_token = CookiesCustom.getToken()
      if (access_token) {
        if (!config.headers) {
          config.headers = {}
        }
        config.headers.Authorization = `Bearer ${access_token}`
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  instance.interceptors.response.use(
    (response) => {
      return response
    },
    async (error) => {
      const originalConfig = error.config
      if (error?.response?.status === 401 && !isRefreshToken) {
        try {
          isRefreshToken = true
          const result = await _instance.post('/token/refresh')
          const { access_token } = result.data.data
          originalConfig.headers = {
            ...originalConfig.headers,
            Authorization: `Bearer ${access_token}`
          }

          if (access_token) {
            const times = jwtDecode(access_token).exp as number
            CookiesCustom.setToken(access_token, String(times + 24 * 60 * 60 * 1000))
          }

          if (refreshToken) {
            const times = jwtDecode(refreshToken).exp as number
            CookiesCustom.setRefreshToken(refreshToken, String(times + 24 * 60 * 60 * 1000))
          }

          return await _instance(originalConfig)
        } catch (err) {
          CookiesCustom.removeToken()
          // CookiesCustom.removeRefreshToken()
          //     window.location.href = `${redirectHref}/login?redirect_url=${okrLink}`
          return await Promise.reject(err)
        } finally {
          isRefreshToken = false
        }
      }
      return Promise.reject(error)
    }
  )
  return instance
}

const instanceAxios = createAxiosClient()

export { instanceAxios }
