import group_permission from './group_permission'
import store from './store'
import user_portal from './user_portal'
import me from './me'
import area from './area'
import campaign from './campaign'
import org_unit from './org_unit'
import portal from './portal'
import customer_care from './customer_care'
import template from './template'
const Api = {
  me,
  portal,
  group_permission,
  store,
  area,
  user_portal,
  org_unit,
  campaign,
  customer_care,
  template
}
export * from './interface'
export default Api
