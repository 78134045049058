import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CustomerCareEntity, TemplateEntity, TemplateParam, TemplateStateEntity } from 'models'
import { TemplateUtil } from 'utils/template'
const initialState: TemplateStateEntity = {
  content: '',
  cursor: 0
}

const slice = createSlice({
  name: 'TEMPLATE_STATE',
  initialState: initialState,
  reducers: {
    setTemplate: (state, { payload }: PayloadAction<TemplateEntity | undefined>) => {
      state.template = payload
    },
    changeContent: (state, { payload }: PayloadAction<string>) => {
      state.content = payload
    },
    setCursor: (state, { payload }: PayloadAction<number>) => {
      state.cursor = payload
    },
    setCustomer: (state, { payload }: PayloadAction<CustomerCareEntity | undefined>) => {
      state.customer = payload
    },
    useSyntax: (state, { payload }: PayloadAction<TemplateParam>) => {
      state.content = TemplateUtil.insertSyntax(state.content, payload, state.cursor)
    },
    removeSyntax: (state, { payload }: PayloadAction<TemplateParam>) => {
      state.content = TemplateUtil.removeSyntax(state.content, payload)
    }
  }
})
export const { actions: templateActions, reducer: templateReducer } = slice
