/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { EnumCustomerImportCategory, ImportCustomerEntity, ImportStateEntity } from 'models'

const initialState: ImportStateEntity = {
  category: EnumCustomerImportCategory.ALL
}
const slice = createSlice({
  name: 'IMPORT_CUSTOMER_STATE',
  initialState,
  reducers: {
    setFile: (state, { payload }: PayloadAction<File | undefined>) => {
      state.file = payload
      if (payload === undefined) {
        state.is_preview = false
      }
    },
    setPreview: (state, { payload }: PayloadAction<boolean>) => {
      state.is_preview = payload
    },
    selectCategory: (state, { payload }: PayloadAction<EnumCustomerImportCategory>) => {
      state.category = payload
    },
    setCustomers: (state, { payload }: PayloadAction<ImportCustomerEntity[]>) => {
      state.customers = payload
    }
  }
})
export const { actions: importActions, reducer: importReducer } = slice
